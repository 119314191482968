import React from 'react'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'
import qs from 'qs'

import PageHeader from '../components/MapPageHeader';
import Layout from '../components/Layout';

import MapPageObject from '../components/MapPageObject';
import './MapPage.css';

/**
 * Filter posts by published. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {posts} object
 */
export const byDate = posts => {
  if(isViewAll()) return posts;
  return posts.filter(post => post.status.toLowerCase() === 'published');
}

//Override the date/published filters
function isViewAll(){
    try{
      if (typeof window !== 'undefined') {
        var field = 'viewall';
        var url = (window.location.href).toLowerCase();
        if(url.indexOf('?' + field + '=') !== -1)
            return true;
        else if(url.indexOf('&' + field + '=') !== -1)
            return true;
        }
      return false
    }catch(err){
        return false;
    }
  }

/**
 * filter posts by category.
 *
 * @param {posts} object
 * @param {title} string
 * @param {contentType} string
 */
export const byCategory = (posts, title, contentType) => {
  const isCategory = contentType === 'mapCategories'
  const byCategory = post =>
    post.categories &&
    post.categories.filter(cat => cat.category === title).length
  return isCategory ? posts.filter(byCategory) : posts
}

// Export Template for use
export const MapPageTemplate = ({
  title,
  subtitle,
  featuredImage,
  posts = [],
  mapCategories = [],
  enableSearch = false,
  lat,
  long,
  contentType,
  activeLocation=null,
  setActiveLocation=function(location){ this.activeLocation=location; console.log("CHANGE LOCATION");}
}) => (
  <Location>
    {({ location }) => {
      let filteredPosts =
        posts && !!posts.length
          ? byCategory(byDate(posts), title, contentType)
          : []

      let queryObj = location.search.replace('?', '')
      queryObj = qs.parse(queryObj)

      if (enableSearch && queryObj.s) {
        const searchTerm = queryObj.s.toLowerCase()
        filteredPosts = filteredPosts.filter(post =>
          post.frontmatter.title.toLowerCase().includes(searchTerm)
        )
      }

      return (
        <main className="MapPage">
          <PageHeader
            title={title}
            subtitle={subtitle}
            backgroundImage={featuredImage}
          />

          {/*!!mapCategories.length && (
            <section className="section thin">
              <div className="container">
                <PostCategoriesNav enableSearch={false} allLink={"/map/"} categories={mapCategories} />
              </div>
            </section>
          )*/}

          {!!posts.length && (
            <MapPageObject locations={filteredPosts} lat={lat} lng={long} zoom={12} />
          )}

          {/*!!posts.length && (
            <section className="section">
              <div className="container">
                <PostSection posts={filteredPosts} />
              </div>
            </section>
          )*/}
        </main>
      )
    }}
  </Location>
)

// Export Default BlogIndex for front-end
const MapPage = ({ data: { page, posts, mapCategories } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <MapPageTemplate
      {...page}
      {...page.fields}
      {...page.frontmatter}
      posts={posts.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
      mapCategories={mapCategories.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
    />
  </Layout>
)

export default MapPage

export const pageQuery = graphql`
  ## Query for BlogIndex data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query MapPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      fields {
        contentType
      }
      frontmatter {
        title
        excerpt
        template
        subtitle
        lat
        long
        address
        phone
        islanding
        website
        mapStory
        iscovidmap
        featuredImage {
          childImageSharp{
            fluid(maxWidth: 250, maxHeight: 150) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        } 
      }
    }

    posts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "posts" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          fields {
            slug
          }
          frontmatter {
            title
            date
            lat
            long
            address
            islanding
            iscovidmap
            status
            categories {
              category
            }
            rating
            phone
            website
            mapStory
            featuredImage {
              childImageSharp{
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    mapCategories: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "mapCategories" } } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            featuredImage {
              childImageSharp{
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
